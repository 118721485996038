export default {
    $form : null,
    form_id : null,
    sections : null,

    first_name : null,
    surname : null,
    email_address : null,
    telephone : null,
    address : null,
    company : null,

    residential: false,

    stages : {},
    init: function(){
        jQuery(document).on('cf.form.init', this.initFormEvents.bind(this));

        var contact = document.getElementById('class-contact');

        if(contact && window.location.hash) {
            contact.classList.remove('hidden');
        }

        window.addEventListener('hashchange', function() {
            if(this.location.hash === "#contact") {
                let contact = document.getElementById('class-contact');
                if(contact) {
                    contact.classList.remove('hidden');
                }
            } else {
                contact.classList.add('hidden');
            }
        });
    },
    initFormEvents: function(event, data){
        var _self = this,
            state = data.state;

        if ('CF5f02fff7e6cb6' === data.formId) {
            //get state
            var state = data.state;
            console.log("Inside form.")
            //disable the field to show programmatically created value.
            //IMPORTANT- Change "fld_7788423_1" to the ID of the field you want to be changed by this code
            jQuery( '#fld_9152805' ).prop( 'disabled' );
        }

        if(typeof data.$form[0] === 'undefined'){
            return false;
        }


        _self.$form = data.$form[0];
        _self.form_id = jQuery(_self.$form).attr('id');
        _self.stages = {
            stage_0_residential: [_self.form_id + "-row-2"],
            stage_0_commercial: [_self.form_id + "-row-3"],
            stage_1: [_self.form_id + "-row-4"],
            stage_2: [_self.form_id + "-row-5"],
            stage_3: [_self.form_id + "-row-6", _self.form_id + "-row-7", _self.form_id + "-row-8", _self.form_id + "-row-9", _self.form_id + "-row-10"],
            stage_4: [_self.form_id + "-row-11"]
        };
        _self.sections = _self.$form.querySelectorAll('.row');

        console.log(_self.sections);

        for(let i = 0; i < _self.sections.length; i++) {
            if(i === 0){
                continue;
            }

            _self.sections[i].style.display = 'none';
        }

        // Type
        state.events().subscribe('fld_3380871_1',  _self.showStage.bind(_self));
        // No of Bedrooms
        state.events().subscribe('fld_3248246_1',  _self.showStage.bind(_self));
        // Approx Size
        state.events().subscribe('fld_4017363_1', _self.showStage.bind(_self));
        // Reason
        state.events().subscribe('fld_6660971_1', _self.showStage.bind(_self));
        // Reason
        state.events().subscribe('fld_942624_1', _self.showStage.bind(_self));


        /** PERSONAL DETAILS */

        //first name
        state.events().subscribe('fld_5624278_1', function (fieldID, value) {
            _self.first_name = value;
            _self.checkDetails(_self);
        });

        //surname
        state.events().subscribe('fld_7985027_1', function (fieldID, value) {
            _self.surname = value;
            _self.checkDetails(_self);
        });

        //email address
        state.events().subscribe('fld_7779136_1', function (fieldID, value) {
            _self.email_address = value;
            _self.checkDetails(_self);
        });

        //telephone
        state.events().subscribe('fld_9946052_1', function (fieldID, value) {
            _self.telephone = value;
            _self.checkDetails(_self);
        });

        state.events().subscribe('fld_1034534_1', function (fieldID, value) {
            _self.telephone = value;
            _self.checkDetails(_self);
        });
        //end telephone

        //company (Not required)
        state.events().subscribe('fld_9578271_1', function (fieldID, value) {
            _self.company = value;
            _self.checkDetails(_self);
        });

        //address (Not required)
        state.events().subscribe('fld_1395972_1', function (fieldID, value) {
            _self.address = value;
            _self.checkDetails(_self);
        });
    },
    showStage: function(fieldID, value){
        var _self = this,
            stage_key = null,
            section_idx = [];

        switch(fieldID){
            case 'fld_3380871_1':
                stage_key = (value === 'Commercial') ? 'stage_0_commercial' : 'stage_0_residential';
                _self.residential = (value !== 'Commercial');
                break;
            case 'fld_3248246_1':
            case 'fld_4017363_1':
                stage_key = 'stage_1';
                break;
            case 'fld_6660971_1':
                stage_key = 'stage_2';
                break;
            case 'fld_942624_1':
                stage_key = 'stage_3';
                break;
            default:
                break;
        }

        if(stage_key === null){
            return false;
        }

        Array.from(_self.sections).forEach(function(el, idx){
            if(_self.stages[stage_key].indexOf(el.id) !== -1){
                section_idx.push(idx);
            }
        });

        section_idx.forEach(function(index){
            _self.sections[index].style.display = 'block';

            if(stage_key === 'stage_2'){
                var values = ['PAT Test', 'Legionella Risk Assessment', 'Gas Safety Certificate', 'EICR Certificate'];
                var values_other = ['Asbestos Survey', 'TM44/Aircon', 'EPC'];

                if(_self.sections[index].querySelector('.form-toggle_label, .btn') !== null){
                    _self.sections[index].querySelectorAll('.form-toggle_label, .btn').forEach(el => {
                        el.parentNode.style.display = 'flex';

                        var val = el.innerText;

                        if(values.indexOf(val) !== -1){
                            el.parentNode.style.display = (_self.residential) ? 'flex' : 'none';
                        }

                        if(values_other.indexOf(val) !== -1){
                            el.parentNode.style.display = (_self.residential) ? 'none' : 'flex';
                        }

                        if(values.indexOf(val) === -1 && values_other.indexOf(val) === -1){
                            el.parentNode.style.display = 'flex';
                        }
                    });
                }
            }
        });

        _self.sections[section_idx[0]].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });


        Array.from(_self.sections).forEach(function(el, idx){
            if(section_idx.length && (idx > section_idx[section_idx.length - 1])){
                setTimeout(function(){
                    el.style.display = 'none';
                }, 50);
            }
        });
    },
    checkDetails: function(_self) {
        console.log('checkdetails');
        if(_self.first_name !== null && _self.surname !== null && _self.email_address !== null && _self.telephone !== null) {
            if(_self.$form.querySelector('.last_row').style.display === 'none'){
                _self.$form.querySelector('.last_row').style.display = 'block';
                _self.$form.querySelector('.last_row').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }
};