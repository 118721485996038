/* globals jQuery */

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'fslightbox';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import WOW from 'wow.js';
import GLightbox from 'glightbox';

import Swiper from 'swiper/js/swiper';
import MicroModal from "micromodal";
import Macy from 'macy';

import Accordion from './lib/Accordion';
import HomeSlider from './modules/HomeSlider';
import Maps from './modules/Map';
import AnimateScroll from "./modules/AnimateScroll";
import QuoteForm from "./modules/QuoteForm";
import StickyScrolling from "./modules/StickyScrolling";
import Nav from './modules/Nav';
import AnchoredCTA from "./modules/AnchoredCTA";
import ReviewsSwiper from "./modules/ReviewsSwiper";
import AnimatedScroll from "./lib/AnimatedScroll";
import Timeline from './modules/Timeline';
import CardImageSlider from "./modules/CardImageSlider";
import Quote from "./modules/QuoteForm";
import AddressLookup from './modules/AddressLookup';
import ClassDropdown from "./modules/ClassDropdown";

window.sjones = {
	init: function() {
		const lightbox = GLightbox();

		document.addEventListener('keyup', ev => {
			console.log(ev);
			if(ev.code === 'Backquote'){
				debugger;
			}
		});

		MicroModal.init({
			onShow: modal => console.info(`${modal.id} is shown`), // [1]
			onClose: modal => console.info(`${modal.id} is hidden`), // [2]
			openTrigger: 'data-custom-open', // [3]
			closeTrigger: 'data-custom-close', // [4]
			disableScroll: true, // [5]
			disableFocus: false, // [6]
			awaitOpenAnimation: false, // [7]
			awaitCloseAnimation: false, // [8]
			debugMode: true // [9]
		});

		if(document.querySelector('.timeline') !== null){
			Quote.init();
		}

		if(document.querySelector('.lookup-address') !== null){
			document.querySelectorAll('.lookup-address').forEach(el => {
				el.postcodelookup = new AddressLookup(el);
			});
		}

		if(document.querySelector('#news-category') !== null){
			document.querySelector('#news-category').addEventListener('change', ev => {
				window.location = ev.target.value;
			});
		}

		this.initCustomDropdown();

		jQuery( document ).on( 'cf.add', () => {
			this.initCustomDropdown();
		});

		if(document.querySelector('.timeline') !== null){
			document.querySelectorAll('.timeline').forEach(el => {
				el.timeline = new Timeline(el);
			});
		}

		if(document.querySelector('.card-image-swiper') !== null){
			document.querySelectorAll('.card-image-swiper').forEach(el => {
				el.slider = new CardImageSlider(el);
			});
		}

		if(document.querySelector('#home-slider') !== null){
			document.querySelectorAll('#home-slider').forEach(el => {
				el.swiper = new HomeSlider(el);
			});
		}

		if(document.querySelector('.accordion') !== null){
			document.querySelectorAll('.accordion').forEach(el => {
				el.accordion = new Accordion(el);
			});
		}

		if(document.querySelector('.variation-swiper') !== null){
			document.querySelectorAll('.variation-swiper').forEach(el => {
				el.swiper = new VariationSwiper(el);
			});
		}

		if(document.querySelector('.reviews-swiper') !== null){
			document.querySelectorAll('.reviews-swiper').forEach(el => {
				el.swiper = new ReviewsSwiper(el);
			});
		}

		if(document.querySelector('.sticky-scrolling') !== null){
			document.querySelectorAll('.sticky-scrolling').forEach(el => {
				el.sticky = new StickyScrolling(el);
			});
		}

		if(document.querySelector('.process-slider') !== null){
			document.querySelectorAll('.process-slider').forEach(el => {
				el.swiperObj = new Swiper(el, {
					speed: 400,
					allowTouchMove: true,
					effect : 'slide',
					autoHeight: true,
					autoplay: false,
					on: {},
					navigation: {
						nextEl: el.parentNode.querySelector('.process-slider-btn--next'),
						prevEl: el.parentNode.querySelector('.process-slider-btn--prev'),
					},
				});
			});
		}

		if(document.querySelector('#class-contact') !== null){
			document.querySelectorAll('#class-contact').forEach(el => {
				// el.quoteForm = new QuoteForm(el);
				console.log('jere');
				QuoteForm.init();
			});
		}
		if(document.querySelector('#sales-cta') !== null){
			document.querySelectorAll('#sales-cta').forEach(el => {
				console.log('jer');
				el.anchoredCTA = new AnchoredCTA(el);
			});
		}

		if(document.querySelector('.review-grid') !== null){
			document.querySelectorAll('.review-grid').forEach(el => {
				el.macy = Macy({
					container: '.review-grid',
					trueOrder: false,
					waitForImages: false,
					margin: 40,
					columns: 3,
					breakAt: {
						992: 2,
						600: 1
					}
				});
			});
		}

		if(document.querySelector('input[type="file"]') !== null){
			document.querySelectorAll('input[type="file"]').forEach(el => {
				el.addEventListener('change', ev => {
					let files = ev.target.files,
						reader = new FileReader();

					files.forEach(file => {
						reader.onload = (function(theFile) {
							return function(e) {
								ev.target.previousElementSibling.innerText = theFile.name;
							};
						})(file);

						// Read in the image file as a data URL.
						reader.readAsDataURL(file);
					});
				});
			});
		}

		// if(document.body.classList.contains('page-template-landing') || document.body.classList.contains('single-post')){
			AnimatedScroll.init();
		// }

		var wow = new WOW({
			boxClass: 'wow',
			animateClass: 'animated',
			offset: 0,
			mobile: false,
			live: false,
		});
		wow.init();

		Maps.init();
		AnimateScroll.init();
		Nav.init();

		// Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		if(document.querySelector('select.form__control') !== null) {
			document.querySelectorAll('select.form__control').forEach(el => {
				if(el.value.length)
					el.classList.add('has-value');

				el.addEventListener('change', ev => {
					ev.preventDefault();


					if(ev.target.value.length){
						ev.target.classList.add('has-value');
					} else {
						ev.target.classList.remove('has-value');
					}
				});
			});
		}
	},
	initCustomDropdown(){
		if(document.querySelector('.class-dropdown') !== null){
			document.querySelectorAll('.class-dropdown').forEach(el => {
				if(typeof el.dropdown === 'undefined'){
					el.dropdown = new ClassDropdown(el);
				}
			});
		}
	}
	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

if(typeof NodeList.prototype.forEach !== 'function'){
	NodeList.prototype.forEach = Array.prototype.forEach;
}

if(typeof NodeList.prototype.filter !== 'function'){
	NodeList.prototype.filter = Array.prototype.filter;
}

if(typeof FileList.prototype.forEach !== 'function'){
	FileList.prototype.forEach = Array.prototype.forEach;
}

window.sjones.init();

// SVG Sprite Loader
// eslint-disable-next-line no-unused-vars
const __sprite__ = {
	path: '../../resources/images/icons/**/*.svg', //Source SVGs path relative to the compiled js (dist/js)
	name: 'images/icons.svg', //Compiled file path relative to dist/
};

svgxhr({
	filename: './wp-content/themes/class/dist/images/icons.svg', //URL to fetch
});
